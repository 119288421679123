export default [
  {
    title: 'Utenti',
    route: 'apps-users-list',
    icon: 'UserIcon',
  },
  {
    title: 'Contatti',
    route: 'apps-contacts-list',
    icon: 'BookIcon',
  },
  {
    title: 'Sedi',
    route: 'apps-headquarters-list',
    icon: 'MapPinIcon',
  },
  {
    title: 'Reparti',
    route: 'apps-departments-list',
    icon: 'UsersIcon',
  },
  {
    title: 'Sale Riunioni',
    route: 'apps-meetingrooms-list',
    icon: 'MonitorIcon',
  },
  {
    title: 'Sale Riunioni Reception',
    route: 'apps-meetingroomsreception-list',
    icon: 'MonitorIcon',
  },
  {
    title: 'Veicoli',
    route: 'apps-vehicles-list',
    icon: 'TruckIcon',
  },
  // {
  //   title: 'Proposte Ferie',
  //   route: 'apps-holidayproposals-list',
  //   icon: 'CompassIcon',
  // },
  {
    title: 'Veicoli Reception',
    route: 'apps-vehiclesreception-list',
    icon: 'TruckIcon',
  },
  {
    title: 'Prospetto Ferie',
    route: 'apps-justificationproposals-list',
    icon: 'ListIcon',
  },
  {
    title: 'Calendario',
    route: 'apps-calendar',
    icon: 'CalendarIcon',
  },
  {
    title: 'Calendario Reception',
    route: 'apps-calendarreception',
    icon: 'CalendarIcon',
  },
  {
    title: 'Calendari Utente',
    route: 'apps-calendarusers-list',
    icon: 'LinkIcon',
  },
  {
    title: 'Timbrature',
    route: 'apps-worktimes-list',
    icon: 'ClockIcon',
  },
  // {
  //   title: 'Chat',
  //   route: 'apps-chat',
  //   icon: 'MessageSquareIcon',
  // },
  {
    title: 'Comunicazioni',
    route: 'apps-conversations',
    icon: 'MessageSquareIcon',
  },
  {
    title: 'Richieste Ferie',
    route: 'apps-justifications-list',
    icon: 'ClipboardIcon',
  },
  {
    title: 'Note Spesa',
    route: 'apps-expensenotes-list',
    icon: 'DollarSignIcon',
  },
  {
    title: 'Viaggi',
    route: 'apps-businesstrips-list',
    icon: 'GlobeIcon',
  },
  // {
  //   title: 'Compiti',
  //   route: 'apps-todo',
  //   icon: 'CheckSquareIcon',
  // },
  // {
  //   header: 'Pagine',
  //   icon: 'PackageIcon',
  //   children: [

  //     {
  //       title: 'Invoice',
  //       icon: 'FileTextIcon',
  //       children: [
  //         {
  //           title: 'List',
  //           route: 'apps-invoice-list',
  //         },
  //         {
  //           title: 'Preview',
  //           route: { name: 'apps-invoice-preview', params: { id: 4987 } },
  //         },
  //         {
  //           title: 'Edit',
  //           route: { name: 'apps-invoice-edit', params: { id: 4987 } },
  //         },
  //         {
  //           title: 'Add',
  //           route: { name: 'apps-invoice-add' },
  //         },
  //       ],
  //     },
  //   ],
  // },
]
